<template>
  <div>
    <validation-observer ref="addClientForm">
      <b-row class="d-lg-flex justify-content-between">
        <b-col sm="12" md="7" lg="7" class="">
          <div class="card p-lg-3 p-2 pb-lg-5 pb-2 mb-1">
            <div>
              <h6 class="">Name of the Client</h6>
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="clientName"
                  v-model="addForm.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="mt-1">
              <h6 class="">About the Client</h6>
              <b-form-group>
                <b-form-textarea
                  id="about"
                  v-model="addForm.about"
                  type="text"
                  placeholder="About"
                />
              </b-form-group>
            </div>
            <div class="mt-2">
              <h6 class="mr-2">Company Employee Strength</h6>
              <div class="d-flex">
                <b-form-radio-group
                  id="employeeStrength"
                  v-model="addForm.strength"
                  :options="empStrengthOptions"
                  class="mt-1 font-weight-bold"
                  name="employeeStrength"
                />
              </div>
            </div>
            <div>
              <h4 class="mt-3 mb-2 bold">Location Details</h4>
            </div>
            <div class="">
              <h6 class="">Address line 1</h6>
              <b-form-input
                id="address1"
                v-model="addForm.address1"
                placeholder=""
              />
            </div>
            <div class="mt-1">
              <h6 class="">Address line 2</h6>
              <b-form-input
                id="address2"
                v-model="addForm.address2"
                placeholder=""
              />
            </div>
            <div class="d-lg-flex d-md-flex">
              <div class="w-lg-50 w-md-50 w-100 mr-2">
                <h6 class="mt-1">City</h6>
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <b-form-input
                    id="city"
                    v-model="addForm.city"
                    type="text"
                    placeholder="City"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="w-lg-50 w-md-50 w-100">
                <h6 class="mt-1">State</h6>
                <validation-provider
                  #default="{ errors }"
                  name="State"
                  rules="required"
                >
                  <b-form-input
                    id="state"
                    v-model="addForm.state"
                    type="text"
                    placeholder="State"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
            <div class="d-lg-flex d-md-flex">
              <div class="w-lg-50 w-md-50 w-100 mr-2">
                <h6 class="mt-1">ZIPCODE</h6>
                <b-form-input
                  id="zipCode"
                  v-model="addForm.zipCode"
                  placeholder=""
                />
              </div>
              <div class="w-lg-50 w-md-50 w-100">
                <h6 class="mt-1">Country</h6>
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <v-select
                    id="country"
                    v-model="addForm.country"
                    label="title"
                    :options="countryOption"
                    :get-option-label="getSelected"
                    :get-option-value="getOptionValue"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
            <div class="mt-3 ml-0 mr-0 mb-0">
              <h4 class="my-2 bold">Payment Structure</h4>

              <b-col class="border rounded-lg px-2 py-2">
                <div>
                  <h6 class="mt-1">Hourly Pricing (Phone)</h6>
                  <b-input-group prepend="$" class="input-group-merge">
                    <b-form-input
                      id="hourlyPricingPhone"
                      v-model="addForm.hourlyPricingPhone"
                      placeholder=""
                    />
                  </b-input-group>
                </div>
                <div>
                  <h6 class="mt-1">Hourly Pricing (Face to Face)</h6>
                  <b-input-group prepend="$" class="input-group-merge">
                    <b-form-input
                      id="hourlyPricingFace"
                      prepend="$"
                      v-model="addForm.hourlyPricingFace"
                      placeholder=""
                    />
                  </b-input-group>
                </div>
              </b-col>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="5" lg="5" class="">
          <div class="card px-lg-3 px-2">
            <b-col class="rounded-lg my-3">
              <h4 class="bold">Contact Details</h4>
              <div class="mt-2">
                <h6>Email ID</h6>
                <b-form-input
                  id="emailID"
                  v-model="addForm.emailID"
                  type="email"
                  placeholder=""
                />
              </div>
              <b-row class="mt-1">
                <b-col lg="5" md="6" sm="12">
                  <h6 class="mt-1">Country code</h6>
                  <b-form-select
                    v-model="addForm.selectCountryCode"
                    :options="countryCodeOptions"
                  />
                </b-col>
                <b-col lg="7" md="6" sm="12">
                  <h6 class="mt-1">Phone Number</h6>
                  <b-form-input
                    id="phoneNumber"
                    v-model="addForm.phone"
                    placeholder=""
                  />
                </b-col>
              </b-row>
              <div class="mt-2">
                <h6 class="mt-1">Website</h6>
                <validation-provider
                  #default="{ errors }"
                  name="Website"
                  rules="required"
                >
                  <b-form-input
                    id="website"
                    v-model="addForm.website"
                    type="text"
                    placeholder="Website"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="mt-3 mb-2">
                <h4 class="bold">Social Presence</h4>
              </div>
              <div>
                <h6 class="mt-1">Linkedin</h6>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon size="1.5x" icon="LinkedinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="addForm.linkedin"
                    placeholder="Search"
                  />
                </b-input-group>
              </div>
              <div>
                <h6 class="mt-1">Facebook</h6>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon size="1.5x" icon="FacebookIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="addForm.facebook"
                    placeholder="Search"
                  />
                </b-input-group>
              </div>
              <div>
                <h6 class="mt-1">Instagram</h6>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon size="1.5x" icon="InstagramIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="addForm.instagram"
                    placeholder="Search"
                  />
                </b-input-group>
              </div>
              <div>
                <h6 class="mt-1">Skype</h6>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <b-img
                      href="#foobar"
                      :src="require('@/assets/images/icons/skype.png')"
                      class=""
                    />
                  </b-input-group-prepend>
                  <b-form-input v-model="addForm.skype" placeholder="Search" />
                </b-input-group>
              </div>
              <!-- <div class="mt-4">
                <div>
                  <h6>Attach Files</h6>
                  <div
                    v-for="(file, index) in attachFiles"
                    :key="file.name"
                    class="d-flex justify-content-between align-items-center p-1 mb-1 file_bg"
                  >
                    <h6 class="my-auto">{{ file.name }}</h6>
                    <b-img
                      @click="removeFile(index)"
                      class="text-white"
                      src="./../../assets/images/icons/crossIcon.png"
                    />
                  </div>
                </div>
                <b-form-file
                  v-model="attachFiles"
                  multiple
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="handleAttachFiles"
                />
              </div> -->
            </b-col>
          </div>
          <div class="card px-lg-3 px-2">
            <b-col class="rounded-lg my-3">
              <h4 class="bold">Super User Credentials</h4>
              <div class="mt-2">
                <h6>Login Email</h6>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="emailID1"
                    v-model="addForm.user_email"
                    type="email"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="mt-2">
                <h6 class="mt-1">Login Password</h6>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-form-input
                    id="password"
                    v-model="addForm.user_password"
                    type="password"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
          </div>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col cols="12" class="">
          <div class="card px-lg-3 px-2 pt-2 py-2">
            <h4 class="mt-3">Other Details</h4>
            <b-form
              ref="form"
              class="repeater-form"
              @submit.prevent="repeateAgain"
            ></b-form>
            <b-row
              v-for="(item, index) in noteItems"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col cols="12" class="my-1">
                <div class="d-flex justify-content-between">
                  <h6>Notes about the Client</h6>
                  <div>
                    <b-img
                      @click="removeItem(index)"
                      class="ml-2"
                      src="./../../assets/images/icons/Icon.png"
                    />
                  </div>
                </div>

                <b-form-textarea
                  id="textarea-rows"
                  placeholder="Write Here..."
                  v-model="item.note"
                  rows="3"
                  cols="12"
                />
              </b-col>
            </b-row>

            <div @click="repeateAgain" class="my-2 d-flex align-items-center">
              <b-avatar
                :src="require('@/assets/images/icons/plus-circle@2x.png')"
                variant="light-secondary"
                class="mr-1"
              />
              <h5 class="my-0">Add Another Question</h5>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-col class="px-0">
        <div class="card p-lg-3 p-2 d-none d-lg-block d-md-block">
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-warning"
              class="mr-2"
              @click="handleSubmit('draft')"
            >
              Save and Draft
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class=""
              @click="handleSubmit('publish')"
            >
              Save and Publish
            </b-button>
          </div>
        </div>
      </b-col>
    </validation-observer>
  </div>
</template>

<script>
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { email, required } from "@validations"
import {
  BAvatar,
  BButton,
  BCol,
  BForm,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BFormGroup,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import axios from "@/libs/axios"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BButton,
    BAvatar,
    BFormRadioGroup,
    BInputGroup,
    BInputGroupPrepend,
    BImg,
    BFormSelect,
    vSelect,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // add question
      noteItems: [
        {
          id: 1,
        },
      ],
      nextTodoId: 2,
      //  form

      required,
      email,
      clientName: "",
      clientDetail: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      hourlyPricingFace: "",
      hourlyPricingPhone: "",
      emailID: "",
      phoneNumber: "",
      website: "",
      linkedin: "",
      facebook: "",
      instagram: "",
      skype: "",
      attachFiles: [],
      selectCountryCode: "",
      selectEmpStrength: "",
      clientNotes: "",
      countries: [
        { title: "India" },
        { title: "America" },
        { title: "Bangladesh" },
      ],
      empStrengthOptions: [
        { text: "1-10", value: "1-10" },
        { text: "11-50 ", value: "11-50 " },
        { text: "51-100", value: "51-100" },
        { text: "101-500 ", value: "101-500 " },
        { text: "500 Above", value: "500 Above" },
      ],
      countryCodeOptions: [
        { value: "+61", text: "Australia(+61)" },
        { value: "+689", text: "French(+689)" },
      ],
      industryOptions: [{ title: "Option 1" }, { title: "Option 2" }],
      countryOption: [],
      addForm: {
        name: "",
        about: "",
        strength: "1-10",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        hourlyPricingPhone: "",
        hourlyPricingFace: "",
        emailID: "",
        selectCountryCode: "",
        phone: "",
        zipCode: "",
        website: "",
        linkedin: "",
        facebook: "",
        instagram: "",
        skype: "",
        user_email: "",
        user_password: "",
        notes: "",
        status: "",
        payment_phone: "",
      },
    }
  },
  mounted() {
    this.getCountries()
  },
  methods: {
    // attach file
    handleAttachFiles(e) {
      this.attachFiles = e.target.files
    },
    removeFile(index) {
      this.attachFiles.splice(index, 1)
    },
    getSelected(option) {
      return (option && option.name) || ""
    },
    getOptionValue(option) {
      return (option && option.name) || ""
    },
    // form repeater
    repeateAgain() {
      this.noteItems.push({
        id: (this.nextTodoId += this.nextTodoId),
      })
    },
    removeItem(index) {
      this.noteItems.splice(index, 1)
    },

    async handleSubmit(status) {
      this.$refs.addClientForm.validate().then((success) => {
        if (success) {
          const formData = {
            name: this.addForm.name,
            about: this.addForm.about,
            strength: this.addForm.strength,
            address1: this.addForm.address1,
            address2: this.addForm.address2,
            city: this.addForm.city,
            state: this.addForm.state,
            country: this.addForm.country.name,
            hourlyPricingPhone: this.addForm.hourlyPricingPhone,
            hourlyPricingFace: this.addForm.hourlyPricingFace,
            emailID: this.addForm.emailID,
            selectCountryCode: this.addForm.selectCountryCode,
            phone: this.addForm.phone,
            zipCode: this.addForm.zipCode,
            website: this.addForm.website,
            linkedin: this.addForm.linkedin,
            facebook: this.addForm.facebook,
            instagram: this.addForm.instagram,
            skype: this.addForm.skype,
            user_email: this.addForm.user_email,
            user_password: this.addForm.user_password,
            notes: this.noteItems,
            status,
            payment_phone: this.addForm.payment_phone,
          }
          axios
            .post("/add_client", formData)
            .then((res) => {
              if (res.data.error) {
                console.log(res.data.messages)
                let errorText = ""
                let errorCount = 0
                // eslint-disable-next-line no-restricted-syntax, no-unused-vars
                for (const [key, value] of Object.entries(res.data.messages)) {
                  console.log(key, value)
                  if (errorCount === 0) {
                    errorText += value[0]
                  } else {
                    errorText += ` & ${value[0]} `
                  }
                  // eslint-disable-next-line no-plusplus
                  errorCount++
                }
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Something went Wrong!",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                    text: errorText,
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Candidate Addition`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Created Candidate List!`,
                  },
                })
                this.$router.push({
                  path: "/clients-list",
                })
                this.modalShow = false
              }
            })
            .catch((error) => {
              console.log(error)
              errorResponse(error, this.$router)
              this.modalShow = false
            })
        }
      })
    },
    async getCountries() {
      axios
        .get("/countries")
        .then((res) => {
          this.countryOption = res.data.countries
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
.img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
}
.file_bg {
  background-color: #dcdcdc;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
